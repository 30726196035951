import React from 'react'
import ReactDOM from 'react-dom'
import { Router } from '@reach/router'
import App from './app'
import serverData, { init as initServerData } from './utils/server-data'
import { ContextProvider as WorldReadyContextProvider } from '@paypalcorp/worldready-react'
import { WorldReadyProvider } from '@paypalcorp/pp-react-worldready'
import { getBasePath } from './utils'
import { get } from 'lodash'

initServerData()
let root = document.getElementById('root')

const { requestURI } = serverData
const basePath = getBasePath(requestURI) || requestURI

if (!root) {
  root = document.createElement('div')
  document.body.appendChild(root)
}

ReactDOM.render(
  <WorldReadyContextProvider locale={get(serverData, 'locality.locale')}>
    <WorldReadyProvider>
      <Router basepath={basePath}>
        <App path="*" serverData={serverData} />
      </Router>
    </WorldReadyProvider>
  </WorldReadyContextProvider>,
  root,
)
