import React from 'react'
import { Router } from '@reach/router'
import {
  LoadingSpinner,
  PAYPAL_THEME,
  ThemeWrapper,
} from '@paypalcorp/pp-react'
import { Logger as clientLogger, LOG_LEVEL } from 'beaver-logger'
import { initialize as fptiInitialize } from './lib/fpti-analytics'
import PropTypes from 'prop-types'
import { get } from 'lodash'
import ppEnv from 'environment-paypal'

const MobileAppDownload = React.lazy(() => import('./screens/mobile-app'))
const DonePage = React.lazy(() => import('./screens/done-page'))
const WelcomeOfferLandingPage = React.lazy(() =>
  import('./screens/offers/welcome'),
)
const Referee = React.lazy(() => import('./screens/mgm/referee'))
const Referrer = React.lazy(() => import('./screens/mgm/referrer'))

const logger = clientLogger({
  url: '/cgp/log',
  // Log level to display in the browser console (only in dev)
  logLevel:
    ppEnv.isProd() || process.env.NODE_ENV === 'production'
      ? LOG_LEVEL.WARN
      : LOG_LEVEL.DEBUG,
})

function App({ serverData }) {
  fptiInitialize()
  logger.info('CLIENT', {
    m_flow: serverData.flowName || '',
    m_intent: serverData.intent || '',
    m_src: serverData.downloadSource || '',
    m_tsrce: serverData.trafficSource || '',
    m_browser: get(serverData, 'deviceDetails.browserName', ''),
    m_browser_version: get(serverData, 'deviceDetails.browserVersion', ''),
    m_device_os: get(serverData, 'deviceDetails.deviceOS', ''),
    m_device_os_version: get(serverData, 'deviceDetails.deviceOSVersion', ''),
    m_device_type: get(serverData, 'deviceDetails.deviceType', ''),
    message: 'Loading App component',
  }) //client side logging

  return (
    <ThemeWrapper theme={PAYPAL_THEME}>
      <div data-testid="app-container">
        <React.Suspense
          fallback={
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                minHeight: '100vh',
              }}
            >
              <LoadingSpinner screenReaderText="loading" size="xl" />
            </div>
          }
        >
          <Router>
            <MobileAppDownload path="/app-download" serverData={serverData} />
            <MobileAppDownload path="/flow/app-download" serverData={serverData} />
            <MobileAppDownload
              path="/public/flow/app-download"
              serverData={serverData}
            />
            <MobileAppDownload
              path="/public/app-download"
              serverData={serverData}
            />
            <MobileAppDownload path="/mobile-app/*" serverData={serverData} />
            <DonePage path="/done-page" serverData={serverData} />
            <WelcomeOfferLandingPage
              path="/offer/welcome"
              serverData={serverData}
            />
            <Referee path="/mgm/referee" serverData={serverData} />
            <Referrer path="/mgm/referrer" serverData={serverData} />
          </Router>
        </React.Suspense>
      </div>
    </ThemeWrapper>
  )
}

App.propTypes = {
  serverData: PropTypes.object,
}

export default App
