import { get } from 'lodash'
import { trackClick, trackMORS } from '../lib/fpti-analytics'

const getBasePath = (requestURI) => {
  const browserPath = get(window, 'location.pathname', '')
  const countryRegex = new RegExp(`(/[a-zA-Z][a-zA-Z2])?(${requestURI})`)
  let basePath = requestURI

  if (!browserPath.startsWith(requestURI, 0)) {
    const regexResult = countryRegex.exec(browserPath) || []
    basePath =
      regexResult.length > 1 ? `${regexResult[1]}${requestURI}` : requestURI
  }
  return basePath
}

const loadFraudnetConfig = (options) => {
  try {
    const script = document.createElement('script')
    script.src = options.fraudnetSrc
    script.setAttribute('nonce', options.nonce)
    document.body.appendChild(script)
  } catch (e) {
    // Ignore
  }
}

const handleShare = async (deviceSharing) => {
  if (!deviceSharing) {
    return
  }

  const {
    isAndroid,
    isiOS,
    isWebView,
    text: shareLinkText,
    title,
    url: shareUrl,
  } = deviceSharing
  const text = `${shareLinkText} ${shareUrl}`
  try {
    await navigator.share({ text, title })
  } catch (err) {
    if (isWebView) {
      try {
        if (isAndroid) {
          // eslint-disable-next-line no-unused-expressions
          window?.Android?.shareInvite(text)
        } else {
          // eslint-disable-next-line no-undef,no-unused-expressions
          isiOS && webkit?.messageHandlers?.shareInvite.postMessage(text)
        }
      } catch (e) {
        console.error('ERROR in sharing.')
      }
    }
  }
}

/**
 * Base click handler for:
 * 1) tracking purposes
 * 2) open a new window by passing a url
 * 3) native device sharing capability
 * @param method HTTP method like GET, POST, etc.
 * @param trackingUrl URL to be called
 * @param deviceSharing = { isAndroid, isiOS, isWebView, text: '', title: '', url: '' }
 */
const handleClick = async (
  {
    fptiTracking,
    morsClickTrackingUrl = '',
    url = null,
    target = '_self',
    deviceSharing = null,
  },
  event,
) => {
  event.preventDefault()
  const element = event.currentTarget,
    link = element.getAttribute('data-name')
  trackClick({
    options: {
      ...fptiTracking,
      link,
    },
  })
  if (morsClickTrackingUrl) {
    trackMORS({
      method: 'GET',
      url: morsClickTrackingUrl,
    })
  }
  handleShare(deviceSharing)
  if (url) {
    window.open(url, target)
  }
}

const copyToClipboard = async (value) => {
  try {
    await navigator.clipboard.writeText(value)
  } catch (error) {
    console.error(error)
  }
}
export { copyToClipboard, getBasePath, loadFraudnetConfig, handleClick }
