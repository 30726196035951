let _initialized = false

// This queue is here to prevent a race condition.
// Since an analytics event can fire before the analytics client-side script
// has been initialized, we're queuing the events that fired while it's initializing,
// and fire all of them sequentially once initialization has completed.
const _q = []

export const ANALYTICS_TYPES = {
  TRACK_CLICK: 'analytics/track_click',
  TRACK_IMPRESSION: 'analytics/track_impression',
  TRACK_MORS: 'analytics/track_mors',
}

export function initialize() {
  if (window.PAYPAL && window.PAYPAL.analytics) {
    window.PAYPAL.analytics.setupComplete = paypalAnalyticsSetupCompleted
    // call it anyway,
    // in case there's a weird race condition where `setupComplete` is already called from the analytics lib
    // before this was loaded.
    paypalAnalyticsSetupCompleted()
  }
}

export function paypalAnalyticsSetupCompleted() {
  // don't init if the analytic lib doesn't have a an instance yet
  // don't init twice
  if (_initialized || !getFPTIInstance()) {
    return
  }

  _initialized = true
  _flushQueue()
}

function getFPTIInstance() {
  return (
    window.PAYPAL.analytics.instance || new window.PAYPAL.analytics.Analytics()
  )
}

export function trackImpression(payload) {
  track(ANALYTICS_TYPES.TRACK_IMPRESSION, payload)
}

export function trackClick(payload) {
  track(ANALYTICS_TYPES.TRACK_CLICK, payload)
}

export function trackMORS(payload) {
  track(ANALYTICS_TYPES.TRACK_MORS, payload)
}

export function track(type, payload) {
  if (_initialized) {
    switch (type) {
      case ANALYTICS_TYPES.TRACK_CLICK:
        _trackClick(payload)
        break
      case ANALYTICS_TYPES.TRACK_IMPRESSION:
        _trackImpression(payload)
        break
      case ANALYTICS_TYPES.TRACK_MORS:
        _trackMORS(payload)
        break
      default:
        break
    }
  } else {
    _enqueueEvent(type, payload)
  }
}

function _flushQueue() {
  while (_q.length) {
    const ev = _q.shift()
    track(ev.type, ev.payload)
  }
}

function _enqueueEvent(type, payload) {
  _q.push({ type, payload })
}

function _trackClick({ options = {} }) {
  const clickPayload = {
    data: {
      comp: 'growthnodeweb',
      vers: 'customer',
      pgtf: 'Nodejs',
      evnt_type: 'cl',
      ...options,
    },
  }
  getFPTIInstance().recordClick(clickPayload)
  clean(options)
}

function _trackImpression({ options = {} }) {
  const impressionPayload = {
    data: {
      comp: 'growthnodeweb',
      vers: 'customer',
      pgtf: 'Nodejs',
      evnt_type: 'im',
      ...options,
    },
  }
  getFPTIInstance().recordImpression(impressionPayload)
  clean(options)
}

/**
 * A simple AJAX utility for calling fire and forget URLs (e.g. mors impression/tracking urls)
 * @param method HTTP method like GET, POST, etc.
 * @param trackingUrl URL to be called
 */
function _trackMORS(payload) {
  const { method, url } = payload

  if (!method || !url || url === '') {
    return
  }

  const xmlhttp = new XMLHttpRequest()
  xmlhttp.open(method, url, true)
  xmlhttp.send()
}

function clean(data) {
  Object.keys(data).forEach((key) => {
    delete window.fpti[key]
  })
}
